import React, { useContext, useEffect, useState } from "react";
import "../../styles/fragments/SelectCurrencyFragment.css";
import usdIcon from "../../assets/usdIcon 2.svg";
import ngnIcon from "../../assets/ngnIcon.svg";
import rwfIcon from "../../assets/rwfIcon.svg";
import eurIcon from "../../assets/eurIcon.svg";
import kesIcon from "../../assets/kesicon.svg";
import gbpIcon from "../../assets/gpbIcon.svg";
import cadIcon from "../../assets/cad-img.svg";
import zarIcon from "../../assets/ZAR-img.svg";
import { useOnClickOutside } from "../../utils/helper/UseOnClickOutside";
import { useSelector } from "react-redux";
import ActionContext from "../../context/ActionContext";

const SelectCurrencyFragment = ({ onSelect, className }) => {
  const [showDrop, setShowDropDown] = useState(false);
  const actionCtx = useContext(ActionContext);
  const { paymentInfo } = useSelector((state) => state.paymentLinks);

  const currencyList = [
    { name: "USD", img: usdIcon },
    { name: "NGN", img: ngnIcon },
    { name: "GBP", img: gbpIcon },
    { name: "EUR", img: eurIcon },
    { name: "KES", img: kesIcon },
    { name: "RWF", img: rwfIcon },
    { name: "CAD", img: cadIcon },
    { name: "ZAR", img: zarIcon },
  ];
  const arrowDownIcon = (
    <svg
      className="img"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 9L12.5 15L18.5 9"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const [currencyListVal, setCurrencyListVal] = useState([]);
  const testingCurrencyDropRef = useOnClickOutside(() => {
    setShowDropDown(false);
  });

  // const stringArray = ['CAD', 'EUR', 'GBP', 'GHS', 'KES', 'KHS', 'NGN', 'USD', 'ZAR'];

  useEffect(() => {
    getNewList(paymentInfo?.currencies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInfo]);

  const getNewList = (stringArray) => {
    if (stringArray?.length > 0) {
      const list = stringArray?.map((currency) => {
        // Find the object with the matching name property
        const matchingObject = currencyList?.find(
          (obj) => obj?.name?.toLowerCase() === currency?.toLowerCase()
        );
        return matchingObject
          ? { ...matchingObject }
          : { name: currency, img: "" };
      });
      const selectedObj = list?.find(
        (chi) => chi.name === paymentInfo?.currency
      );
      // console.log(paymentInfo?.currency_type);
      // setSelectedObj(selectedObj);
      !actionCtx?.currencyObj && actionCtx?.setCurrencyObj(selectedObj);
      // console.log(selectedObj);
      onSelect(selectedObj);
      setCurrencyListVal(list);
    } else {
      setCurrencyListVal(currencyList);
    }
  };

  return (
    <div
      ref={testingCurrencyDropRef}
      className={`select-currency-fragment-index-wrap ${className}`}
      // onClick={() => {
      //   console.log(paymentInfo?.currencies);
      //  console.log( getNewList(paymentInfo?.currencies));
      // }}
    >
      <div
        onClick={() => {
          setShowDropDown(!showDrop);
        }}
        className="selected-obj-wrap"
        style={{
          cursor:
            paymentInfo?.currency_type === "multiple"
              ? "pointer"
              : "not-allowed",
        }}
      >
        <div className="cur-img">
          {" "}
          <figure className="img-box">
            <img
              src={actionCtx?.currencyObj?.img || ngnIcon}
              alt=""
              className="img"
            />
          </figure>
        </div>

        <p className="selected-value">
          {actionCtx?.currencyObj?.name || "NGN"}
        </p>
        <div className="down-icon">
          <figure className="img-box">{arrowDownIcon}</figure>
        </div>
      </div>
      {/* select list start */}
      {showDrop && paymentInfo?.currency_type === "multiple" && (
        <div className="select-list card-bg">
          {currencyListVal?.map((chi, idx) => {
            return (
              <div
                key={idx}
                onClick={() => {
                  actionCtx?.currencyObj?.name !== chi?.name &&
                    actionCtx?.setCurrencyObj(chi);
                  setShowDropDown(false);
                  onSelect && onSelect(chi);
                }}
                className={`cur-item ${
                  actionCtx?.currencyObj?.name === chi?.name &&
                  "cur-item-selected"
                }`}
              >
                {" "}
                <div className="cur-img">
                  {" "}
                  <figure className="img-box">
                    <img src={chi?.img} alt="" className="img" />
                  </figure>
                </div>
                <p className="select-value">{chi?.name || " "}</p>
              </div>
            );
          })}
        </div>
      )}
      {/* select list  end */}
    </div>
  );
};

export default SelectCurrencyFragment;
