// import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useContext, useEffect, useState } from "react";
// import PaymentLinkLayout from "../../layout/payment";
// import useWindowSize from "../../utils/helper/UseWindowSize";
// import FormPaymentDetail from "./FormPaymentDetail";
// import { Oval } from "react-loader-spinner";
import newImgLoader from "../../assets/raven-loader.gif";
import CancelledPayment from "./CancelledPayment";
import SuccessPaymentContent from "./SuccessPayment";
import LinkExpiredContent from "./LinkExpiredContent";
// import AtlasPay from "atlas-pay-sdk";
// import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  ProceedPaymentLinkToSdk,
  ValidatePaymentLinkRef,
} from "../../redux/requestMoney";
import { formatNumWithoutCommaNaira } from "../../utils/helper/Helper";
import ngnIcon from "../../assets/ngnIcon.svg";
import { downloadTransactionRecieptByRef } from "../../redux/billPayment";
// import PayementLinkInvoiceLayout from "../../layout/dashboard/PayementLinkInvoiceLayout";
import FormPaymenDetailTwo from "./FormPaymenDetailTwo";
// import { validateEmail } from "../../utils/ValidateInput";
import { toast } from "@ravenpay/raven-bank-ui";
import useWindowSize from "../../utils/UseWindowSize";
import { validateEmail } from "../../utils/ValidateInput";
import PayementLinkInvoiceLayout from "../layout/PayementLinkInvoiceLayout";
import { useLocation } from "react-router-dom";
import NotFoundLinkContent from "./NotFoundLink";
import AllowPopUpNotification from "./AllowPopUp";
import ActionContext from "../../context/ActionContext";

const AtlasPay = window.AtlasPaySdk;
const PaymentLinkVersionTwoIndex = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const size = useWindowSize();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedObj, setSelectedObj] = useState({ name: "NGN", img: ngnIcon });
  const actionCtx = useContext(ActionContext);
  const [details, setDetails] = useState({
    email: "",
    first_name: "",
    last_name: "",
    amount: "",
    allow_edit_amount: true,
    title: "",
    des: "",
    business_name: "",
    logo: "",
    id: "",
    merchant_name: "",
    payment_method: "",
    payment_amount: "",
    ref: "",
    state: "",
    city: "",
    country: "",
    postal_code: "",
  });

  // AtlasPay.onSuccess = function (data) {
  //   console.log("Payment successful:", data);
  //   setStep(2);
  //   setDetails((prev) => {
  //     return {
  //       ...prev,
  //       payment_method:
  //         data?.message?.payment_method_used === "bank_transfer"
  //           ? "Bank Transfer"
  //           : data?.message?.payment_method_used,
  //       ref: data?.message?.trx_ref,
  //       amount: data?.message?.amount,
  //     };
  //   });
  // };

  AtlasPay.onClose = function (data) {
    // console.log(data);
    if (data?.message?.transaction_success) {
      setStep(2);
      // console.log(data);
      setDetails((prev) => {
        return {
          ...prev,
          payment_method:
            data?.message?.data?.payment_method_used === "bank_transfer"
              ? "Bank Transfer"
              : data?.message?.data?.payment_method_used,
          ref: data?.message?.data?.trx_ref,
          amount: data?.message?.data?.amount,
        };
      });
      AtlasPay.shutdown();
      return;
    }

    AtlasPay.shutdown();
    // console.log(data);
    setStep(3);
  };

  useEffect(() => {
    getInfoRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };
  // const navigate = useNavigate();
  const getInfoRef = async () => {
    // const refInfo = window?.location.href?.split("getravenbank.com/")[1];
    const refInfo = location.pathname?.split("/");
    const obj = {
      payment_ref: refInfo[refInfo.length - 1],
    };
    // navigate(location.pathname?.replace(obj.payment_ref, ""));
    // console.log(obj.payment_ref);
    // return
    // if(refInfo){

    // }
    const data = await dispatch(ValidatePaymentLinkRef(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      setLoading(false);
      const newObj = data?.payload?.data?.data;
      // console.log(newObj);
      if (String(newObj?.is_active) === "0") {
        setStep(0);
        return;
      }
      // console.log(newObj);
      const info = data?.payload?.data?.data;
      setStep(newObj?.is_active);
      setDetails((prev) => {
        return {
          ...prev,
          amount:
            String(newObj?.amount) !== "0"
              ? formatNumWithoutCommaNaira(newObj?.amount)
              : "",
          title: info?.title,
          des: newObj?.description,
          logo: info?.logo,
          business_name: newObj?.name,
          id: newObj?.id,
          allow_edit_amount: newObj?.amount_type === "fixed" ? true : false,
        };
      });
    }
    if (data?.payload?.response?.data?.status === "fail") {
      setLoading(false);
      setStep(4);
    }
  };

  const handleDownloadReceipt = async () => {
    const obj = {
      ref: String(details?.ref),
    };
    // console.log(obj);
    // return
    const data = await dispatch(downloadTransactionRecieptByRef(obj));
    if (data?.payload?.data?.status === "success") {
      // setDownloadCsvId("");
      downloadCsv(data?.payload?.data?.data?.url);
      // downloadCsvId("");
      // console.log(data);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  const [loadingProcess, setLoadingProcess] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({
    email: false,
  });
  const [activeStep, setActiveStep] = useState(1);

  const handleProceedToPayment = async () => {
    if (!validateEmail(details.email)) {
      toast.error("Invalid email address!", { position: "top-right" });
      setError((prev) => {
        return { ...prev, email: true };
      });
      return;
    }
    const obj = {
      first_name: details?.first_name,
      last_name: details?.last_name,
      payment_link_id: String(details?.id),
      amount: Number(formatNumWithoutCommaNaira(details?.amount)),
      email: details?.email,
      currency: actionCtx?.currencyObj?.name || details?.currency,
      // reference: paymentInfo?.reference,
      others: JSON.stringify({
        adress: details?.address,
        city: details?.city,
        state: details?.state,
        country: details?.country,
        postal_code: details?.postal_code,
      }),
    };
    // setLoadingProcess(true);
    // console.log(obj);
    // return;
    const data = await dispatch(ProceedPaymentLinkToSdk(obj));
    // console.log(data);
    // return
    if (data?.payload?.response?.data?.status === "fail") {
      setLoadingProcess(false);
    }
    if (data?.payload?.data?.status === "fail") {
      setLoadingProcess(false);
      if (data?.payload?.data?.message === "Link expired") {
        setStep(0);
      }
    }
    if (data?.payload?.data?.status === "success") {
      setLoadingProcess(false);
      const newObj = data?.payload?.data?.data;
      if (newObj?.currency === "NGN") {
        //  const ref = newObj?.link?.split("?ref=")[1];
        // // console.log(ref);
        // AtlasPay.init(ref);
        openURLInNewTab(newObj?.link);
      } else {
        openURLInNewTab(newObj?.link);
      }
    }
  };
  const [urlToUse, setUrlToUse] = useState("");
  function openURLInNewTab(url) {
    // Open in a new tab only on user interaction (e.g., button click)
    const newTab = window.open(url, "_blank");

    if (newTab) {
      // Pop-up was not blocked
      newTab.focus();
    } else {
      // Pop-up was blocked or not allowed
      // Provide guidance to the user
      setUrlToUse(url);
      setStep(5);
    }
  }

  if (loading) {
    return (
      <>
        <PayementLinkInvoiceLayout>
          <div
            style={{
              width: size.width < 900 ? "90%" : "45rem",
              height: "45rem",
              marginTop: "auto",
              marginBottom: "auto",
            }}
            className="version-two-public-invoice-wrap"
          >
            <div className="invoice-public-content-main-wrap">
              <div
                style={{
                  width: `100%`,
                  height: `100%`,
                  display: "grid",
                  placeItems: "center",
                }}
                className=""
              >
                <figure
                  style={{
                    width: size.width < 900 ? "15rem" : "20rem",
                    height: size.width < 900 ? "15rem" : "20rem",
                  }}
                  className="img-box"
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={newImgLoader}
                    alt=""
                    className="img"
                  />
                </figure>
              </div>
            </div>
          </div>
        </PayementLinkInvoiceLayout>
      </>
    );
  }

  // useEffect(() => {}, [])

  return (
    <>
      <PayementLinkInvoiceLayout
        loading={loadingProcess}
        onClick={() => {
          if (activeStep === 1) {
            if (actionCtx?.currencyObj?.name === "NGN") {
              handleProceedToPayment();
            } else {
              setActiveStep(2);
            }
          }
          if (activeStep === 2) {
            handleProceedToPayment();
          }
        }}
        btnLabel={
          step === 1 && actionCtx?.currencyObj?.name === "NGN"
            ? `Proceed to payment`
            : step === 1 && actionCtx?.currencyObj?.name !== "NGN"
            ? "Proceed"
            : step === 1 &&
              actionCtx?.currencyObj?.name !== "NGN" &&
              activeStep === 2
            ? `Proceed to payment`
            : ""
        }
        disableBtn={
          activeStep === 1
            ? !details?.first_name ||
              !details?.amount ||
              !details?.last_name ||
              !details?.email ||
              !actionCtx?.currencyObj?.name ||
              String(details?.amount) === "0"
            : activeStep === 2
            ? !details?.first_name ||
              !details?.amount ||
              !details?.last_name ||
              !details?.email ||
              !actionCtx?.currencyObj?.name ||
              String(details?.amount) === "0" ||
              !details?.state ||
              !details?.city ||
              !details?.country
            : false
        }
      >
        {/* version two payment link start */}
        <div className="version-two-payment-link-public-wrap-index">
          <div className="wrap-right-content">
            {/* step one start */}
            {step === 1 && (
              <FormPaymenDetailTwo
                handleChange={handleChange}
                details={details}
                setStep={setStep}
                handleProceedToPayment={handleProceedToPayment}
                loading={loadingProcess}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                selectedObj={selectedObj}
                setSelectedObj={setSelectedObj}
              />
            )}
            {/* step one end */}
            {/* step three success start */}
            {step === 2 && (
              <SuccessPaymentContent
                onDownload={handleDownloadReceipt}
                details={details}
                setStep={setStep}
              />
            )}
            {/* step three success end */}
            {/* step four expired start */}
            {step === 4 && <NotFoundLinkContent setStep={setStep} />}
            {/* step four expired end */}
            {/* step two cancelled start */}
            {step === 3 && (
              <CancelledPayment
                setStep={(e) => {
                  getInfoRef();
                  setStep(e);
                }}
              />
            )}
            {/* step two cancelled end */}
            {/* step four expired start */}
            {step === 0 && <LinkExpiredContent setStep={setStep} />}
            {/* step four expired end */}
            {/* step five expired start */}
            {step === 5 && (
              <AllowPopUpNotification url={urlToUse} setStep={setStep} />
            )}
            {/* step five expired end */}
          </div>
        </div>
        {/* version two payment link end */}
      </PayementLinkInvoiceLayout>
    </>
  );
};

export default PaymentLinkVersionTwoIndex;
