import { configureStore } from "@reduxjs/toolkit";
import paymentLinks from "./requestMoney";
import billPayment from "./billPayment";


// const initialState = {};
const store = configureStore({
  reducer: {
    paymentLinks,
    billPayment,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore the headers field in all actions
        ignoredActions: "*",
        ignoredPaths: ["payload.headers"],
      },
    }),
});

export default store;
   