// import PublicPreviewInvoice from "../../pages/dashboard/invoice/PublicPreviewInvoice";
// import PaymentWebPayIndex from "../../pages/payment-web-pay";
import PaymentLinkVersionTwoIndex from "../../pages/payment-web-pay/PaymentLinkVersionTwo";
import PaymentVerificationPage from "../../pages/payment-web-pay/PaymentVerificationPage";
// import PublicPreviewInvoiceVersionTwo from "../../pages/dashboard/invoice/PublicPreviewInvoiceVersionTwo";
// import PaymentLinkVersionTwoIndex from "../../pages/payment-web-pay/PaymentLinkVersionTwo";

export const payment_web_pay_routes_group = [
  // {path: "/invoice-preview", element: <PublicPreviewInvoice />},
  { path: "/pay/", element: <PaymentLinkVersionTwoIndex /> },
  { path: "*", element: <PaymentLinkVersionTwoIndex /> },
  { path: "/verify", element: <PaymentVerificationPage /> },
];

// PublicPreviewInvoiceVersionTwo
