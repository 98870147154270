import { toast } from "@ravenpay/raven-bank-ui";
import axios from "axios";
// import { genCaptchaKeyAsync } from "../components/reusables/ReCaptchaFile";
// import env from "../env";
// import { decrypt, encrypt } from "./EncryptAndDecrypt";
// import { getCookie } from "./CookiesFunction";
// import CryptoJS from "crypto-js";

const API = process.env.REACT_APP_BASE_URL;

/** base url to make request to the BE end point */

const instance = axios.create({
  baseURL: API || "https://baas.getraventest.com",
});
  


instance.interceptors.request.use(
  async (config) => {
    // Encrypt the request here

    // console.log(getCookie("g-rec-response"));
    // const _reToken = getCookie('g-rec-response');
    // console.log(_reToken, "--------------");
    // const toks = await genCaptchaKeyAsync()
    // if (checkToken === "error_string") {
    //   return;
    // }
    if (config.data === undefined || config.data?.type !== "formData") {
      // const encryptedData = encrypt(config.data);
      // config.data = encryptedData;
      // config.headers = {
      //   ...config.headers,
      //   "g-rec-response": toks ,
      // };
      // // console.log(config);
      return config;
    }
  },
  (error) => {
    // console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    // Decrypt the response here
    // const decryptedData = await decrypt(response.data);
    // response.data = decryptedData;
    // console.log(response);
    return response;
  },
  async (error) => {
    if (error.message === "Network Error") {
      // console.log(error);
      toast.error(`${error.message}, check your network settings`);
      return;
    }
    // let val;
    if (error.response.data.status === "fail") {
      // console.log(error);
      // const decryptedErrorData = await decrypt(error.response.data);
      // error.response.data = decryptedErrorData;

      // val = error;
    }

    return error;
  }
  // return Promise.reject(error);
  // }
);

// console.log(env.base_url, process.env.NODE_ENV);

export default instance;
