/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
import { toast } from "@ravenpay/raven-bank-ui";

export const createPaymentLink = createAsyncThunk(
  "create/payment-link",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("create/link", payload);
      // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.message === "Network Error") {
        toast.error(err.message, {
          position: "top-right",
        });
      }
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getPaymentLinks = createAsyncThunk(
  "get/links",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `get/links?per_page=${payload.per_page}&current_page=${
          payload.current_page
        }&search_term=${payload?.search || ""}&status=${
          payload?.status || ""
        }&type=${payload?.type || ""}`,
        payload
      );
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(setPaymentLinks(data?.data));
        return data;
      }
    } catch (err) {
      //
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getPaymentLinksByFilter = createAsyncThunk(
  "admin/get-payment-links-filter",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getPaymentLink = createAsyncThunk(
  "admin/get-payment-link",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(`/get/link/${payload}`, payload);
      // console.log("register", data.data);

      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        // toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        // toast.success(data.data.message, {
        //   theme: "colored",
        // });
        await thunkAPI.dispatch(setPaymentLink(data?.data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const getPaymentLinksPagination = createAsyncThunk(
  "admin/get-payment-links-pagination",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(`get/links/${payload}`, payload);
      console.log("register", data);

      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(setPaymentLinks(data?.data));
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const updatePaymentLink = createAsyncThunk(
  "admin/update-payment-link",
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const data = await axios.post(`edit/link/${id}`, payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const ValidatePaymentLinkRef = createAsyncThunk(
  "admin/uvalidate-payment-link",
  async (payload, thunkAPI) => {
    try {
      // const { id } = payload;
      const data = await axios.get(`/paymentlinks/get?identifier=${payload?.payment_ref || ""}`);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // console.log(data);
        await thunkAPI.dispatch(setPaymentInfo(data?.data?.data));
        // toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const VerifyPaymentLinkRef = createAsyncThunk(
  "admin/verify-payment-link",
  async (payload, thunkAPI) => {
    try {
      // const { id } = payload;
      const data = await axios.get(`/paymentlinks/verify_payment?reference=${payload?.payment_ref}`);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        // toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const ProceedPaymentLinkToSdk = createAsyncThunk(
  "admin/procced-payment-link",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`/paymentlinks/make_payment`, payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {});
        // console.log(data);
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const sharePaymentLink = createAsyncThunk(
  "admin/share-payment-link",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post(`/link/share`, payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const deletePaymentLink = createAsyncThunk(
  "admin/delete-payment-link",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/delete/link", payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const disablePaymentLink = createAsyncThunk(
  "admin/disable-payment-link",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/link/suspend", payload);
      // console.log("register", data);
      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message, {});
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const regeneratePaymentLink = createAsyncThunk(
  "admin/regenerate-link",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("", payload);
      // console.log("register", data);

      if (!data.success) {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        // return data;
      }
      if (data.success) {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(login(data));
        //   return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

export const paymentLinks = createSlice({
  name: "payment-link",
  initialState: {
    paymentLinks: [],
    paymentLink: {},
    isAuth: false,
    loading: false,
    loadingSingle: false,
    paymentInfo: {},
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setPaymentInfo: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.paymentInfo = action.payload;
    },
    setPaymentLinks: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.paymentLinks = action.payload;
    },
    setPaymentLink: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      state.paymentLink = action.payload;
    },
  },
  extraReducers: {
    [getPaymentLinks.pending]: (state) => {
      state.loading = true;
    },
    [getPaymentLinks.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPaymentLinks.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getPaymentLink.pending]: (state) => {
      state.loadingSingle = true;
    },
    [getPaymentLink.fulfilled]: (state) => {
      state.loadingSingle = false;
    },
    [getPaymentLink.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSingle = false;
      state.isAuth = false;
      state = null;
    },
    [sharePaymentLink.pending]: (state) => {
      state.loadingSingle = true;
    },
    [sharePaymentLink.fulfilled]: (state) => {
      state.loadingSingle = false;
    },
    [sharePaymentLink.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingSingle = false;
      state.isAuth = false;
      state = null;
    },
    [createPaymentLink.pending]: (state) => {
      state.loading = true;
    },
    [createPaymentLink.fulfilled]: (state) => {
      state.loading = false;
    },
    [createPaymentLink.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deletePaymentLink.pending]: (state) => {
      state.loading = true;
    },
    [deletePaymentLink.fulfilled]: (state) => {
      state.loading = false;
    },
    [deletePaymentLink.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [disablePaymentLink.pending]: (state) => {
      state.loading = true;
    },
    [disablePaymentLink.fulfilled]: (state) => {
      state.loading = false;
    },
    [disablePaymentLink.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [ProceedPaymentLinkToSdk.pending]: (state) => {
      state.loading = true;
    },
    [ProceedPaymentLinkToSdk.fulfilled]: (state) => {
      state.loading = false;
    },
    [ProceedPaymentLinkToSdk.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getPaymentLinksPagination.pending]: (state) => {
      state.loading = true;
    },
    [getPaymentLinksPagination.fulfilled]: (state) => {
      state.loading = false;
    }, 
    [getPaymentLinksPagination.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, setPaymentLinks, setPaymentLink, setPaymentInfo } = paymentLinks.actions;

export default paymentLinks.reducer;
