// import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
// import PaymentLinkLayout from "../../layout/payment";
// import useWindowSize from "../../utils/helper/UseWindowSize";
// import FormPaymentDetail from "./FormPaymentDetail";
// import { Oval } from "react-loader-spinner";
import newImgLoader from "../../assets/raven-loader.gif";
// import CancelledPayment from "./CancelledPayment";
import SuccessPaymentContent from "./SuccessPayment";
// import LinkExpiredContent from "./LinkExpiredContent";
// import AtlasPay from "atlas-pay-sdk";
// import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  //   ProceedPaymentLinkToSdk,
  //   ValidatePaymentLinkRef,
  VerifyPaymentLinkRef,
} from "../../redux/requestMoney";
// import { formatNumWithoutCommaNaira } from "../../utils/helper/Helper";
// import { downloadTransactionReciept } from "../../redux/transaction";
import { downloadTransactionRecieptByRef } from "../../redux/billPayment";
// import PayementLinkInvoiceLayout from "../../layout/dashboard/PayementLinkInvoiceLayout";
// import FormPaymenDetailTwo from "./FormPaymenDetailTwo";
// import { validateEmail } from "../../utils/ValidateInput";
// import { toast } from "@ravenpay/raven-bank-ui";
import useWindowSize from "../../utils/UseWindowSize";
// import { validateEmail } from "../../utils/ValidateInput";
import PayementLinkInvoiceLayout from "../layout/PayementLinkInvoiceLayout";
import PendingProcessContent from "./PendingProcess";
import FailedPaymentContent from "./FailedPayment";
import NotFoundLinkContent from "./NotFoundLink";

const AtlasPay = window.AtlasPaySdk;
const PaymentVerificationPage = () => {
  const dispatch = useDispatch();
  //   const location = useLocation();
  const size = useWindowSize();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({
    email: "",
    first_name: "",
    last_name: "",
    amount: "",
    allow_edit_amount: true,
    title: "",
    des: "",
    business_name: "",
    logo: "",
    id: "",
    merchant_name: "",
    payment_method: "",
    payment_amount: "",
    ref: "",
  });

  // AtlasPay.onSuccess = function (data) {
  //   console.log("Payment successful:", data);
  //   setStep(2);
  //   setDetails((prev) => {
  //     return {
  //       ...prev,
  //       payment_method:
  //         data?.message?.payment_method_used === "bank_transfer"
  //           ? "Bank Transfer"
  //           : data?.message?.payment_method_used,
  //       ref: data?.message?.trx_ref,
  //       amount: data?.message?.amount,
  //     };
  //   });
  // };

  AtlasPay.onClose = function (data) {
    // console.log(data);
    if (data?.message?.transaction_success) {
      setStep(2);
      // console.log(data);
      setDetails((prev) => {
        return {
          ...prev,
          payment_method:
            data?.message?.data?.payment_method_used === "bank_transfer"
              ? "Bank Transfer"
              : data?.message?.data?.payment_method_used,
          ref: data?.message?.data?.trx_ref,
          amount: data?.message?.data?.amount,
        };
      });
      AtlasPay.shutdown();
      return;
    }

    AtlasPay.shutdown();
    // console.log(data);
    setStep(3);
  };

  useEffect(() => {
    getInfoRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     const obj = { ...details, [name]: value };
  //     setDetails(obj);
  //   };

  const getInfoRef = async () => {
    const refInfo = window?.location.href?.split("?reference=")[1];
    const obj = {
      payment_ref: refInfo || "",
    };
    // console.log(obj);
    // return
    const data = await dispatch(VerifyPaymentLinkRef(obj));
    // console.log(data);
    if (data?.payload?.response?.data?.status === "fail") {
      setLoading(false);
      setStep(4);
    }
    if (data?.payload?.data?.status === "success") {
      setLoading(false);
      const newObj = data?.payload?.data?.data;
      // console.log(newObj);
      if (newObj?.status === "pending" && String(newObj?.is_paid) === "0") {
        // setDetails(newObj)
        setStep(1);
        return;
      }
      // console.log(newObj);
      if (newObj?.status === "paid" && String(newObj?.is_paid) === "1") {
        // console.log(newObj);
        setDetails(newObj);
        setStep(2);
        return;
      }
      if (newObj?.status === "failed") {
        setStep(3);
        return;
      }
    } else {
      setLoading(false);
    }
  };

  const handleDownloadReceipt = async () => {
    const obj = {
      ref: String(details?.reference),
    };
    // console.log(obj);
    // return
    const data = await dispatch(downloadTransactionRecieptByRef(obj));
    if (data?.payload?.data?.status === "success") {
      // setDownloadCsvId("");
      downloadCsv(data?.payload?.data?.data?.url);
      // downloadCsvId("");
      // console.log(data);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  //   const [loadingProcess, setLoadingProcess] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({
    email: false,
  });

  //   const handleProceedToPayment = async () => {
  //     if (!validateEmail(details.email)) {
  //       toast.error("Invalid email address!", { position: "top-right" });
  //       setError((prev) => {
  //         return { ...prev, email: true };
  //       });
  //       return;
  //     }
  //     const obj = {
  //       first_name: details?.first_name,
  //       last_name: details?.last_name,
  //       payment_link_id: String(details?.id),
  //       amount: Number(formatNumWithoutCommaNaira(details?.amount)),
  //       email: details?.email,
  //       currency: details?.currency,
  //       // reference: paymentInfo?.reference,
  //       others: JSON.stringify({
  //         adress: details?.address,
  //       }),
  //     };
  //     setLoadingProcess(true);
  //     // return;
  //     const data = await dispatch(ProceedPaymentLinkToSdk(obj));
  //     console.log(data);
  //     // return
  //     if (data?.payload?.response?.data?.status === "fail") {
  //       setLoadingProcess(false);
  //     }
  //     if (data?.payload?.data?.status === "fail") {
  //       setLoadingProcess(false);
  //       if (data?.payload?.data?.message === "Link expired") {
  //         setStep(0);
  //       }
  //     }
  //     if (data?.payload?.data?.status === "success") {
  //       setLoadingProcess(false);
  //       const newObj = data?.payload?.data?.data;
  //       if (newObj?.currency === "NGN") {
  //         const ref = newObj?.link?.split("?ref=")[1];
  //         // console.log(ref);
  //         AtlasPay.init(ref);
  //         // window.open(newObj?.link, '_blank')
  //       } else {
  //         window.open(newObj?.link, "_blank");
  //       }
  //     }
  //   };

  if (loading) {
    return (
      <>
        <PayementLinkInvoiceLayout>
          <div
            style={{
              width: size.width < 900 ? "90%" : "45rem",
              height: "45rem",
              marginTop: "auto",
              marginBottom: "auto",
            }}
            className="version-two-public-invoice-wrap"
          >
            <div className="invoice-public-content-main-wrap">
              <div
                style={{
                  width: `100%`,
                  height: `100%`,
                  display: "grid",
                  placeItems: "center",
                }}
                className=""
              >
                <figure
                  style={{
                    width: size.width < 900 ? "15rem" : "20rem",
                    height: size.width < 900 ? "15rem" : "20rem",
                  }}
                  className="img-box"
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={newImgLoader}
                    alt=""
                    className="img"
                  />
                </figure>
              </div>
            </div>
          </div>
        </PayementLinkInvoiceLayout>
      </>
    );
  }

  // useEffect(() => {}, [])

  return (
    <>
      <PayementLinkInvoiceLayout>
        {/* version two payment link start */}
        <div className="version-two-payment-link-public-wrap-index">
          <div className="wrap-right-content">
            {/* step one start */}

            {/* step one end */}
            {/* step three success start */}
            {step === 2 && (
              <SuccessPaymentContent
                onDownload={handleDownloadReceipt}
                details={details}
                setStep={setStep}
              />
            )}
            {/* step three success end */}
            {/* step two cancelled start */}
            {step === 4 && <NotFoundLinkContent setStep={setStep} />}
            {/* step two cancelled end */}
            {/* step four expired start */}
            {step === 3 && <FailedPaymentContent setStep={setStep} />}
            {/* step four expired end */}
            {/* step four expired start */}
            {step === 1 && <PendingProcessContent setStep={setStep} />}
            {/* step four expired end */}
          </div>
        </div>
        {/* version two payment link end */}
      </PayementLinkInvoiceLayout>
    </>
  );
};

export default PaymentVerificationPage;
