import { RavenButton } from "@ravenpay/raven-bank-ui";
import React from "react";
import testLogo from "../../assets/cancel-payment-web-img.png";

const CancelledPayment = ({ setStep }) => {
  return (
    <>
      <div className="content content-cancel-wrap">
        <div className="wrap">
          <div className="logo-name-box">
            <figure className="img-box">
              <img src={testLogo} alt="" className="img" />
            </figure>
          </div>
          <p className="title">Payment Cancelled</p>
          <p className="text">
            Unable to carry out your transaction, kindly retry this transaction
            or use a different payment method to carry out your transaction.If
            you have any issues with your transactions{" "}
            <span
              onClick={() => {
                // window.Intercom("show");
                window.fcWidget.open();
              }}
            >
              please contact support.
            </span>
          </p>
        </div>
      </div>
      {/* end of content */}
      {/* btn box start */}
      <div
        style={{ padding: "unset", marginTop: "3rem" }}
        className="btn-box btn-box-two"
      >
        <RavenButton
          onClick={() => setStep(1)}
          color={`green-dark`}
          label={`Retry payment`}
        />
      </div>
      {/* btn box end */}
    </>
  );
};

export default CancelledPayment; 
