import { RavenButton } from "@ravenpay/raven-bank-ui";
import React from "react";
// import testLogo from "../../assets/expired-payment-web-img.png";

const FailedPaymentContent = ({ setStep }) => {

    const failedIcon = <svg className="img" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="47.9999" height="48" rx="24" fill="#FF0F00"/>
    <g filter="url(#filter0_dd_1270_61028)">
    <path d="M30.0004 17.999L18.0005 29.999M18.0005 17.999L30.0004 29.999" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <filter id="filter0_dd_1270_61028" x="10" y="10" width="28" height="29" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="1"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.02 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1270_61028"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="1"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.06 0"/>
    <feBlend mode="normal" in2="effect1_dropShadow_1270_61028" result="effect2_dropShadow_1270_61028"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1270_61028" result="shape"/>
    </filter>
    </defs>
    </svg>
    
  return (
    <>
      <div className="content content-cancel-wrap">
        <div className="wrap">
          <div className="logo-name-box">
            <figure className="img-box">
             {failedIcon}
            </figure>
          </div>
          <p className="title">Payment Failed</p>
          <p className="text">
            You cannot make payment to this link anymore, request a different
            link from the recipient or <span>contact our support</span>
          </p>
        </div>
      </div>
      {/* end of content */}
      {/* btn box start */}
      <div
        style={{ padding: "unset", marginTop: "3rem" }}
        className="btn-box btn-box-two"
      >
        <RavenButton
          onClick={() => {
            // window.Intercom("show");
            window.fcWidget.open();
          }}
          color={`deep-green-dark`}
          label={`Contact Support`}
        />
      </div>
      {/* btn box end */}
    </>
  );
};

export default FailedPaymentContent;
