import { RavenButton } from "@ravenpay/raven-bank-ui";
import React from "react";
import testLogo from "../../assets/expired-payment-web-img.png";

const NotFoundLinkContent = ({ setStep }) => {
  return (
    <>
      <div className="content content-cancel-wrap">
        <div className="wrap">
          <div className="logo-name-box">
            <figure className="img-box">
              <img src={testLogo} alt="" className="img" />
            </figure>
          </div>
          <p className="title">Link Not Found</p>
          <p className="text">
            You cannot make payment to this link, request a different link from
            the recipient or{" "}
            <span
              onClick={() => {
                // window.Intercom("show");
                window.fcWidget.open();
              }}
            >
              contact our support
            </span>
          </p>
        </div>
      </div>
      {/* end of content */}
      {/* btn box start */}
      <div
        style={{ padding: "unset", marginTop: "3rem" }}
        className="btn-box btn-box-two"
      >
        <RavenButton
          onClick={() => {
            // window.Intercom("show");
            window.fcWidget.open();
          }}
          color={`deep-green-dark`}
          label={`Contact Support`}
        />
      </div>
      {/* btn box end */}
    </>
  );
};

export default NotFoundLinkContent;
