import { RavenButton } from "@ravenpay/raven-bank-ui";
import React from "react";
import { useSelector } from "react-redux";
import testLogo from "../../assets/success-payment-web-img.png";
import {
  GetSymbol,
  formatNumWithCommaNairaSymbol,
  lowerCaseWrap,
} from "../../utils/helper/Helper";
import usdIcon from "../../assets/usdIcon 2.svg";
import ngnIcon from "../../assets/ngnIcon.svg";
import rwfIcon from "../../assets/rwfIcon.svg";
import eurIcon from "../../assets/eurIcon.svg";
import kesIcon from "../../assets/kesicon.svg";
import gbpIcon from "../../assets/gpbIcon.svg";
import cadIcon from "../../assets/cad-img.svg";
import zarIcon from "../../assets/ZAR-img.svg";

const currencyList = [
  { name: "USD", img: usdIcon },
  { name: "NGN", img: ngnIcon },
  { name: "GBP", img: gbpIcon },
  { name: "EUR", img: eurIcon },
  { name: "KES", img: kesIcon },
  { name: "RWF", img: rwfIcon },
  { name: "CAD", img: cadIcon },
  { name: "ZAR", img: zarIcon },
];

const SuccessPaymentContent = ({ details, onDownload }) => {
  const { loadingDownload } = useSelector((state) => state.billPayment);

  const getCurrencyObj = (param) => {
    if (param) {
      const obj = currencyList?.find((chi) => chi?.name === param);
      return obj;
    }
  };
  return (
    <>
      <div className="content content-success-wrap">
        {/* top detail box start */}
        <div className="top-detail-box">
          <div className="img-wrap">
            <figure className="img-box">
              <img src={testLogo} alt="" className="img" />
            </figure>
          </div>
          <p className="title">Payment Successful</p>
        </div>
        {/* top detail box end */}
        {/* bottom detail box start */}
        <div className="bottom-detail-box">
          {/* box start */}
          <div className="box">
            <p className="label">Merchant Name</p>
            <p className="value">
              {`${lowerCaseWrap(details?.first_name)} ${details?.last_name}` ||
                "---"}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label">Merchant Email</p>
            <p className="value">{details?.customer_email || "---"}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label">Currency</p>
            <div
              style={{ display: "flex", alignItems: "center", gap: ".7rem" }}
              className="value value-currency"
            >
              <figure
                style={{
                  width: "1.7rem",
                  height: "1.7rem",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
                className="img-box"
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  src={getCurrencyObj(details?.currency)?.img}
                  alt=""
                  className="img"
                />
              </figure>
              <span>{getCurrencyObj(details?.currency)?.name || "---"}</span>
            </div>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label">Payment Amount</p>
            <p className="value">
             <span>{GetSymbol(details?.currency)}</span> <span>
                {" "}
                {formatNumWithCommaNairaSymbol(details?.amount)?.replace(
                  "₦",
                  ""
                ) || "---"}
              </span>
            </p>
          </div>
          {/* box end */}

          {/* box start */}
          <div className="box">
            <p className="label">Payment Reference</p>
            <p className="value">{details?.reference || "---"}</p>
          </div>
          {/* box end */}
        </div>
        {/* bottom detail box end */}
      </div>
      {/* end of content */}
      {/* btn box start */}
      <div
        style={{ padding: "unset", marginTop: "3rem" }}
        className="btn-box btn-box-two"
      >
        <RavenButton
          onClick={onDownload}
          color={`green-dark`}
          label={`Download Receipt`}
          loading={loadingDownload}
        />
      </div>
      {/* btn box end */}
      {/* <input type="text" pattern="[0-9]*" /> */}
    </>
  );
};

export default SuccessPaymentContent;
