// import logo from './logo.svg';
// import "./App.css";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import ScrollToTop from "./helper/ScrollTop";
import {  Route, Routes, useLocation, Navigate } from "react-router-dom";
import store from "./redux/store";
import { payment_web_pay_routes_group } from "./routes/payment-web-pay";
import { RavenToast } from "@ravenpay/raven-bank-ui";
import { useEffect } from "react";
import PaymentLinkVersionTwoIndex from "./pages/payment-web-pay/PaymentLinkVersionTwo";
require("@ravenpay/raven-bank-ui/dist/esm/styles/index.css");

function App() {
  const location = useLocation();

    // const channelID = "";
    useEffect(() => {
      if (
        !location.pathname.includes("/dashboard-") &&
        !location.pathname.includes("/dashboard-verification")
      ) {
        const script = document.createElement("script");
        // script.src = `//fw-cdn.com/10934263/3694369.js?channelId=${channelID}`;
        script.src = `//fw-cdn.com/10934263/3694369.js`;
        script.setAttribute("chat", "true");
  
        // Add an event listener to check when the script has loaded
        script.onload = () => {
          if (window.fcWidget) {
          }
        };
  
        // Append the script to the document's body
        document.body.appendChild(script);
  
        // Cleanup by removing the script from the DOM when the component unmounts
        return () => {
          document.body.removeChild(script);
        };
      }
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <Provider store={store}>
      <div
        style={{
          backgroundColor:
            location?.pathname?.includes("/pay/") ||
            location?.pathname?.includes("/invoice-preview")
              ? "#1b1b1b"
              : "",
        }}
        className="App"
      >
        <Helmet>
          <title>Raven Payment </title>
          <meta
            name="description"
            content="All-in-one banking suite for businesses of all sizes, Transfer, Payments, Invoices, Payroll."
          />
          <meta
            property="og:description"
            content="All-in-one banking suite for businesses of all sizes, Transfer, Payments, Invoices, Payroll."
          />
          <meta
            name="keywords"
            content="bank, banking, loan, savings, roi, Transfer, Invoice, payroll,  interest, nigeria, africa, fintech"
          />
          <meta name="page-topic" content="Banking" />
          <meta name="page-type" content="Landing" />
          <meta name="audience" content="Everyone" />
          <meta
            property="og:image"
            content="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/959d76181716239.652060a529c45.jpeg"
          />
          <meta
            property="og:url"
            content="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/959d76181716239.652060a529c45.jpeg"
          />
          <meta name="robots" content="index, follow" />
          <meta
            property="twitter:image:src"
            content="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/959d76181716239.652060a529c45.jpeg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="GetRavenBank" />
          <meta
            name="twitter:site"
            content="https://www.twitter.com/theravenbank"
          />
          <meta
            httpEquiv="Content-Security-Policy"
            content="default-src * self blob: data: gap:; style-src * self 'unsafe-inline' blob: data: gap:; script-src * 'self' 'unsafe-eval' 'unsafe-inline' blob: data: gap:; object-src * 'self' blob: data: gap:; img-src * self 'unsafe-inline' blob: data: gap:; connect-src self * 'unsafe-inline' blob: data: gap:; frame-src * self blob: data: gap:;"
          />
          <meta
            key="cache-control"
            http-equiv="Cache-Control"
            content="no-cache, no-store, must-revalidate"
          />
          <meta key="pragma" http-equiv="Pragma" content="no-cache" />
          <meta key="expires" http-equiv="Expires" content="0" />
        </Helmet>
        <ScrollToTop />
        <Routes location={location}>
          <Route
            location={location}
            path="/"
            element={<Navigate to="/pay/" />}
          />
          <Route location={location} path="*" element={<PaymentLinkVersionTwoIndex />} />
          {/* auth group start */}
          {payment_web_pay_routes_group?.map((route, idx) => {
            return <Route key={idx} exact {...route} />;
          })}
        </Routes>
      </div>
      <RavenToast />
      {/* <Intercom appID="v4k0zj58"  /> */}
    </Provider>
  );
}

export default App;
