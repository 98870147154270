/* eslint-disable jsx-a11y/alt-text */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utils/axios";
import { toast } from "@ravenpay/raven-bank-ui";

export const buyAirtime = createAsyncThunk(
  "airtime/recharge",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("airtime/recharge", payload);

      if (data?.response?.data?.status === "fail") {
        if (Array.isArray(data?.response?.data?.message)) {
          toast.error(data?.response?.data?.message[0], {
            theme: "colored",
          });
        } else {
          toast.error(data?.response?.data?.message);
        }
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message);

        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message);
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message);
        return err;
      }
    }
  }
);

export const buyData = createAsyncThunk(
  "admin/buy-data",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("data_plan/recharge", payload);

      if (data?.response?.data?.status === "fail") {
        if (Array.isArray(data?.response?.data?.message)) {
          toast.error(data?.response?.data?.message[0], {
            theme: "colored",
          });
        } else {
          toast.error(data?.response?.data?.message);
        }
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message);

        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message);
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message);
      }
      return err;
    }
  }
);

export const buyCablePlan = createAsyncThunk(
  "cable/subscribe",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("cable/subscribe", payload);
      //       // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        if (Array.isArray(data?.response?.data?.message)) {
          toast.error(data?.response?.data?.message[0], {
            theme: "colored",
          });
        } else {
          toast.error(data?.response?.data?.message);
        }
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message);

        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message);
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message);
      }
      return err;
    }
  }
);

export const makeBettingPayment = createAsyncThunk(
  "betting/payment",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("/bet/pay", payload);
      //       // console.log("register", data);

      if (data?.response?.data?.status === "fail") {
        if (Array.isArray(data?.response?.data?.message)) {
          toast.error(data?.response?.data?.message[0], {
            theme: "colored",
          });
        } else {
          toast.error(data?.response?.data?.message);
        }
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message);

        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message);
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message);
      }
      return err;
    }
  }
);

export const getDataPlans = createAsyncThunk(
  "data_plans?network=airtel",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `data_plans?network=${payload?.network}`,
        payload
      );
      // console.log(data);
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(setDataPlans(data.data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 400) {
        toast.error(err.response.data.message);
      }
      // ;
      return err;
    }
  }
);

export const getBetTypes = createAsyncThunk(
  "bet-types",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(`/bet/types`, payload);
      // console.log(data);
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        return data;
      }
      if (data.status === "success") {
        // console.log(data);
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(setBetTypes(data.data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 400) {
        toast.error(err.response.data.message);
      }
      // ;
      return err;
    }
  }
);

export const fetchElectricityTransactions = createAsyncThunk(
  "data_plans?network=airtel",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `electricity/records?=${payload.per_page}&current_page=${payload.current_page}&${payload.date}`,
        payload
      );

      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(setElectricTransactions(data.data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 400) {
        toast.error(err.response.data.message);
      }
      // ;
      return err;
    }
  }
);

export const getCablePlans = createAsyncThunk(
  "cable/providers",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `cable/providers?provider=${payload.provider}`,
        payload
      );
      // console.log(data);
      if (data.status !== "success") {
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        await thunkAPI.dispatch(setCablePlans(data.data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 400) {
        toast.error(err.response.data.message);
      }
      // ;
      return err;
    }
  }
);

export const validateSmartCardNo = createAsyncThunk(
  "cable/validate_account",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("cable/validate_account", payload);
      // console.log("data_plans", data);

      if (data.status !== "success") {
        toast.error(data.message, {
          theme: "colored",
        });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // await thunkAPI.dispatch(setDataPlans(data.data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 400) {
        toast.error(err.response.data.message);
      }
      // ;
      return err;
    }
  }
);

// Electricity bill redux starts here

export const getDiscos = createAsyncThunk(
  "electricity/discos",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get("electricity/discos", payload);
      // console.log(data, "------------------------------");
      if (data.status !== "success") {
        // toast.error(data.message, {
        //   theme: 'colored'
        // });
        return data;
      }
      if (data.status === "success") {
        //   toast.success(data.data.message, {
        //     theme: "colored",
        //   });
        // formatElectricityTypes(data?.data)
        await thunkAPI.dispatch(setAllDiscos(data.data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 400) {
        toast.error(err.response.data.message);
      }
      // ;
      return err;
    }
  }
);

export const validateMeterNumber = createAsyncThunk(
  "electricity/validate_meter",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("electricity/validate_meter", payload);
      // console.log("validate meter", data);
      if (data.status !== "success") {
        toast.error(data.message);
        return data;
      }
      if (data.status === "success") {
        // toast.success(data.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message);
      }

      return err;
    }
  }
);

export const validateBetIdNumber = createAsyncThunk(
  "electricity/bet-id",
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.post("bet/validate_account", payload);
      // console.log("validate meter", data);
      if (data.status !== "success") {
        toast.error(data.message);
        return data;
      }
      if (data.status === "success") {
        // toast.success(data.message, {});
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message);
      }

      return err;
    }
  }
);

export const payElectricityBill = createAsyncThunk(
  "electricity/pay",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.post("electricity/pay", payload);
      // console.log("electricity", data);

      if (data?.response?.data?.status === "fail") {
        if (Array.isArray(data?.response?.data?.message)) {
          toast.error(data?.response?.data?.message[0], {
            theme: "colored",
          });
        } else {
          toast.error(data?.response?.data?.message);
        }
        return data;
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message);

        return data;
      }
      if (data?.data?.status === "success") {
        toast.success(data?.data?.message);
        return data;
      }
    } catch (err) {
      // ;
      if (err.response.data.status === "fail" && err.response.status !== 401) {
        toast.error(err.response.data.message);
      }
      return err;
    }
  }
);

export const downloadTransactionRecieptByRef = createAsyncThunk(
  "transactions/download-transaction-receipt-by-ref",
  async (payload, thunkAPI) => {
    try {
      const data = await axios.get(
        `paymentlink/download_payment_link_receipt?trx_ref=${payload?.ref}`,
        payload
      );
      if (data.message === "Network Error") {
        toast.error(data?.message, {});
      }

      if (data?.response?.data?.status === "fail") {
        toast.error(data?.response?.data?.message, {});
      }
      if (data?.data?.status === "fail") {
        toast.error(data?.data?.message, {});
      }
      if (data?.data?.status === "success") {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // await thunkAPI.dispatch(login(data));
        return data;
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.status === "fail") {
        toast.error(err.response.data.message, {});
      }
      return err;
    }
  }
);

const formatBetTypes = (list) => {
  // console.log(list);
  if (list?.providers?.length > 0) {
    const newList = list?.providers?.map((chi) => {
      return {
        label: chi,
        value: chi,
        logo: `${list?.logo_url?.replace(
          "replaceWithNameOfBetProviderToLowerCase",
          chi?.toLowerCase()
        )}`,
      };
    });
    // console.log(newList);
    return newList;
  }
};

const formatElectricityTypes = (list) => {
  // console.log(list);
  if (list?.discos?.length > 0) {
    const newList = list?.discos?.map((chi) => {
      return {
       ...chi,
        logo: `${list?.logo_url?.replace(
          "replaceWithNameOfElectricityDiscoToUpperCase",
          chi?.code
        )}`,
      };
    });
    // console.log(newList);
    return newList;
  }
};

export const billPayment = createSlice({
  name: "bill-payment",
  initialState: {
    allDiscos: [],
    dataPlans: [],
    cablePlans: [],
    betTypes: [],
    electricity_transactions: {
      data: [
        {
          id: 2,
          customer_name: "AKERELE OJO",
          meter_no: "45082500013",
          merchant_reference: "202212250958FEIGCEF",
          reference: "202212250858EFDFIJD",
          merchant_email: "ayenikehinded4@gmail.com",
          customer_address:
            "10 SARIYU OLUSESI STR M/NO 90/S25/82847 AGODO EGBE",
          customer_phone_number: "08026160979",
          token: "12147016320613261295",
          status: 3,
          provider: "IKEDC",
          meter_type: "prepaid",
          amount: 1000,
          currency: "NGN",
          business_id: 6,
          meta_data: null,
          created_at: "2022-12-25T07:58:40.000Z",
          updated_at: "2022-12-25T07:58:40.000Z",
          deleted_at: null,
        },
      ],
    },
    // paymentLinks: [],
    // paymentLink: {},
    isAuth: false,
    loading: false,
    loadingVerify: false,
    loadingDownload: false,
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setPaymentLinks: (state, action) => {
      //   localStorage.setItem("user", JSON.stringify(action.payload));
      state.isAuth = true;
      //   state.paymentLinks = action.payload;
    },

    setDataPlans: (state, action) => {
      state.isAuth = true;
      state.dataPlans = action.payload;
    },

    setBetTypes: (state, action) => {
      state.isAuth = true;
      state.betTypes = formatBetTypes(action.payload);
    },

    setCablePlans: (state, action) => {
      state.isAuth = true;
      state.cablePlans = action.payload;
    },

    setElectricTransactions: (state, action) => {
      state.isAuth = true;
      state.electricity_transactions = action.payload;
    },

    setAllDiscos: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      state.allDiscos = formatElectricityTypes(action.payload);
      state.isAuth = true;
      state.electricity = formatElectricityTypes(action.payload);
    },
  },
  extraReducers: {
    [buyAirtime.pending]: (state) => {
      state.loading = true;
    },
    [buyAirtime.fulfilled]: (state) => {
      state.loading = false;
    },
    [buyAirtime.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [buyData.pending]: (state) => {
      state.loading = true;
    },
    [buyData.fulfilled]: (state) => {
      state.loading = false;
    },
    [buyData.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [makeBettingPayment.pending]: (state) => {
      state.loading = true;
    },
    [makeBettingPayment.fulfilled]: (state) => {
      state.loading = false;
    },
    [makeBettingPayment.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [buyCablePlan.pending]: (state) => {
      state.loading = true;
    },
    [buyCablePlan.fulfilled]: (state) => {
      state.loading = false;
    },
    [buyCablePlan.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [validateMeterNumber.pending]: (state) => {
      state.loadingVerify = true;
    },
    [validateMeterNumber.fulfilled]: (state) => {
      state.loadingVerify = false;
    },
    [validateMeterNumber.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingVerify = false;
      state.isAuth = false;
      state = null;
    },

    [validateBetIdNumber.pending]: (state) => {
      state.loadingVerify = true;
    },
    [validateBetIdNumber.fulfilled]: (state) => {
      state.loadingVerify = false;
    },
    [validateBetIdNumber.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingVerify = false;
      state.isAuth = false;
      state = null;
    },

    [fetchElectricityTransactions.pending]: (state) => {
      state.loading = true;
    },
    [fetchElectricityTransactions.fulfilled]: (state) => {
      state.loading = false;
    },
    [fetchElectricityTransactions.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [payElectricityBill.pending]: (state) => {
      state.loading = true;
    },
    [payElectricityBill.fulfilled]: (state) => {
      state.loading = false;
    },
    [payElectricityBill.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },

    [validateSmartCardNo.pending]: (state) => {
      state.loading = true;
    },
    [validateSmartCardNo.fulfilled]: (state) => {
      state.loading = false;
    },
    [validateSmartCardNo.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [downloadTransactionRecieptByRef.pending]: (state) => {
      state.loadingDownload = true;
    },
    [downloadTransactionRecieptByRef.fulfilled]: (state) => {
      state.loadingDownload = false;
    },
    [downloadTransactionRecieptByRef.rejected]: (state) => {
      // localStorage.removeItem("token");
      state.loadingDownload = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setPaymentLinks,
  setElectricTransactions,
  setAllDiscos,
  setDataPlans,
  setCablePlans,
  setBetTypes,
} = billPayment.actions;

export default billPayment.reducer;
