import { RavenInputField } from "@ravenpay/raven-bank-ui";
import React, { useContext, useState } from "react";
import testLogo from "../../assets/payment-company-logo-test-img.svg";
// import AtlasPay from "atlas-pay-sdk";
import {
  formatNumWithCommaNairaSymbol,
  // formatNumWithoutCommaNaira,
  lowerCaseWrap,
  trimLongString,
} from "../../utils/helper/Helper";
// import { validateEmail } from "../../utils/helper/ValidateInput";
// import { useDispatch } from "react-redux";
// import { ProceedPaymentLinkToSdk } from "../../redux/requestMoney";
import SelectCurrencyFragment from "../../components/fragments/SelectCurrencyFragment";
import ActionContext from "../../context/ActionContext";

// const AtlasPay = window.AtlasPaySdk;

const FormPaymenDetailTwo = ({
  setSelectedObj,
  selectedObj,
  handleChange,
  details,
  handleProceedToPayment,
  loading,
  activeStep,
  setActiveStep,
}) => {
  // const { paymentInfo } = useSelector((state) => state.paymentLinks);
  const defaultIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="img"
    >
      <path
        d="M15 21V15.6C15 15.0399 15 14.7599 14.891 14.546C14.7951 14.3578 14.6422 14.2049 14.454 14.109C14.2401 14 13.9601 14 13.4 14H10.6C10.0399 14 9.75992 14 9.54601 14.109C9.35785 14.2049 9.20487 14.3578 9.10899 14.546C9 14.7599 9 15.0399 9 15.6V21M3 7C3 8.65685 4.34315 10 6 10C7.65685 10 9 8.65685 9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 8.65685 16.3431 10 18 10C19.6569 10 21 8.65685 21 7M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V6.2C21 5.0799 21 4.51984 20.782 4.09202C20.5903 3.71569 20.2843 3.40973 19.908 3.21799C19.4802 3 18.9201 3 17.8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202C3 4.51984 3 5.07989 3 6.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
        stroke="#646464"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const [error, setError] = useState({
    email: false,
  });
  const actionCtx = useContext(ActionContext);

  const arrowLeft = (
    <svg
      className="img"
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.69702 1.52574C8.10099 1.17666 8.10099 0.610155 7.69702 0.261068C7.2919 -0.087023 6.63446 -0.087023 6.22933 0.261068L0.302977 5.36766C-0.100993 5.71675 -0.100993 6.28325 0.302977 6.63234L6.22933 11.7389C6.63446 12.087 7.2919 12.087 7.69702 11.7389C8.10099 11.3898 8.10099 10.8233 7.69702 10.4743L2.50109 5.99807L7.69702 1.52574Z"
        fill="#020202"
        fillOpacity="0.82"
      />
    </svg>
  );

  return (
    <>
      {/* outside content title and image start */}
      <div className="outside-content-title-img-box">
        <div className="logo-name-box">
          {details?.logo ? (
            <figure className="img-box">
              <img src={details?.logo || testLogo} alt="" className="img" />
            </figure>
          ) : (
            <>
              <figure className="img-box img-box-default">{defaultIcon}</figure>
            </>
          )}
          <p style={{ textTransform: "capitalize" }} className="name">
            {trimLongString(details?.business_name, 20) || "---"}
          </p>
        </div>
      </div>
      {/* outside content title and image end */}
      <div className="content">
        {/* back text start  */}
        {activeStep === 2 && (
          <div
            onClick={() => {
              setActiveStep(1);
            }}
            className="back-text-box"
          >
            <div className="back-icon">
              <figure className="img-box">{arrowLeft}</figure>
            </div>
            <p className="text">Back</p>
          </div>
        )}
        {/* back text end */}
        {/* link title start */}
        <div className="title-detail-box">
          <p className="title"> {lowerCaseWrap(details?.title) || "---"}</p>
          <p className="text">{lowerCaseWrap(details?.des) || "---"}</p>
        </div>
        {/* link title end */}
        {/* step one start -------------- */}
        {activeStep === 1 && (
          <>
            {/* form content start */}
            <div className="form-content-box">
              {/* two group start */}
              <div className="two-group">
                <RavenInputField
                  color="green-dark"
                  label={`First Name`}
                  placeholder={`e.g Timi`}
                  name="first_name"
                  onChange={handleChange}
                  value={details?.first_name}
                  capitalize
                />
                <RavenInputField
                  color="green-dark"
                  label={`Last Name`}
                  placeholder={`e.g Ayeni`}
                  name="last_name"
                  onChange={handleChange}
                  value={details?.last_name}
                  capitalize
                />
              </div>
              {/* two group end */}

              <RavenInputField
                name="email"
                onChange={(e) => {
                  handleChange(e);
                  setError((prev) => {
                    return { ...prev, email: false };
                  });
                }}
                showError={error?.email}
                value={details?.email}
                color="green-dark"
                label={`Email*`}
                placeholder={`E.g getbusiness@gmail.com`}
                type={`email`}
              />

              <div className="cur-amount-box-label">
                <p className="label">Enter Amount </p>
                <div className="cur-amount-box">
                  <div className="cur-box-drop">
                    <SelectCurrencyFragment
                      selectedObj={selectedObj}
                      setSelectedObj={setSelectedObj}
                      onSelect={(param) => {
                        const obj = {
                          target: {
                            name: "currency",
                            value: param?.name,
                          },
                        };
                        handleChange(obj);
                      }}
                      className={`payment-cur`}
                    />
                  </div>
                  <RavenInputField
                    disabled={details?.allow_edit_amount}
                    name="amount"
                    onChange={handleChange}
                    value={details?.amount}
                    color="green-dark"
                    //   label={`Amount*`}
                    placeholder={`0.00`}
                    type={`number`}
                    thousandFormat
                  />
                </div>
              </div>
              {/* <div
            className={`address-currency-box ${
              details?.currency !== "NGN" && "address-currency-box-show"
            }`}
          >
            <RavenInputField
              name="address"
              onChange={handleChange}
              value={details?.address}
              color="green-dark"
              label={`Address*`}
              placeholder={`E.g No 5 jaiyeola..`}
              type={`text`}
              capitalize
            />
          </div> */}
            </div>
            {/* form content end */}
          </>
        )}
        {/* step one end -------------- */}
        {/* step two start -------------- */}
        {activeStep === 2 && (
          <>
            {/* form content start */}
            <div className="form-content-box">
              <RavenInputField
                name="address"
                onChange={handleChange}
                value={details?.address}
                color="green-dark"
                label={`Address*`}
                placeholder={`E.g No 5 jaiyeola..`}
                type={`text`}
                capitalize
              />
              {/* two group start */}
              <div className="two-group">
                <RavenInputField
                  color="green-dark"
                  label={`State`}
                  placeholder={`State`}
                  name="state"
                  onChange={handleChange}
                  value={details?.state}
                  capitalize
                />
                <RavenInputField
                  color="green-dark"
                  label={`City`}
                  placeholder={`City`}
                  name="city"
                  onChange={handleChange}
                  value={details?.city}
                  capitalize
                />
              </div>
              {/* two group end */}

              <RavenInputField
                name="postal_code"
                onChange={(e) => {
                  handleChange(e);
                  // setError((prev) => {
                  //   return { ...prev, postal_code: false };
                  // });
                }}
                value={details?.postal_code}
                color="green-dark"
                label={`Postal Code *`}
                placeholder={`E.g 100560`}
                type={`text`}
              />
              <RavenInputField
                name="country"
                onChange={handleChange}
                value={details?.country}
                color="green-dark"
                label={`Country*`}
                placeholder={`Enter country`}
                type={`text`}
                capitalize
              />
            </div>
            {/* form content end */}
          </>
        )}
        {/* step two end -------------- */}

        {/* btn box start */}
        <div style={{ marginTop: "2rem" }} className="btn-box">
          <RavenInputField
            loading={loading}
            onSubmit={() => {
              if (activeStep === 1) {
                if (actionCtx?.currencyObj?.name === "NGN") {
                  handleProceedToPayment();
                } else {
                  setActiveStep(2);
                }
              }
              if (activeStep === 2) {
                handleProceedToPayment();
              }
            }}
            type={`submit`}
            value={
              actionCtx?.currencyObj?.name === "NGN" && activeStep === 1
                ? `Pay ${
                    actionCtx?.currencyObj?.name === "NGN"
                      ? formatNumWithCommaNairaSymbol(details?.amount || "0")
                      : formatNumWithCommaNairaSymbol(
                          details?.amount || "0"
                        )?.replace("₦", "")
                  }`
                : actionCtx?.currencyObj?.name !== "NGN" && activeStep === 2
                ? `Pay ${
                    actionCtx?.currencyObj?.name === "NGN"
                      ? formatNumWithCommaNairaSymbol(details?.amount || "0")
                      : formatNumWithCommaNairaSymbol(
                          details?.amount || "0"
                        )?.replace("₦", "")
                  }`
                : `Proceed`
            }
            color={`green-dark`}
            disabled={
              activeStep === 1
                ? !details?.first_name ||
                  !details?.amount ||
                  !details?.last_name ||
                  !details?.email ||
                  !actionCtx?.currencyObj?.name ||
                  String(details?.amount) === "0"
                : activeStep === 2
                ? !details?.first_name ||
                  !details?.amount ||
                  !details?.last_name ||
                  !details?.email ||
                  !actionCtx?.currencyObj?.name ||
                  String(details?.amount) === "0" ||
                  !details?.state ||
                  !details?.city ||
                  !details?.country
                : false
            }
          />
        </div>
        {/* btn box end */}
      </div>
      {/* end of content */}
    </>
  );
};

export default FormPaymenDetailTwo;
