import { RavenButton } from "@ravenpay/raven-bank-ui";
import React from "react";
// import testLogo from "../../assets/expired-payment-web-img.png";

const pendingLogo = (
  <svg
    className="img"
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="47.9999" height="48" rx="24" fill="#EA872D" />
    <g filter="url(#filter0_dd_1270_61026)">
      <path
        d="M24.5 28V24M24.5 20H24.51M34.4999 24C34.4999 29.5228 30.0228 34 24.5 34C18.9771 34 14.5 29.5228 14.5 24C14.5 18.4772 18.9771 14 24.5 14C30.0228 14 34.4999 18.4772 34.4999 24Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_1270_61026"
        x="10.5"
        y="10"
        width="28"
        height="29"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.02 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1270_61026"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1270_61026"
          result="effect2_dropShadow_1270_61026"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1270_61026"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const AllowPopUpNotification = ({ setStep, url }) => {
  return (
    <>
      <div className="content content-cancel-wrap">
        <div className="wrap">
          <div className="logo-name-box">
            <figure className="img-box">
              {/* <img src={testLogo} alt="" className="img" /> */}
              {pendingLogo}
            </figure>
          </div>
          <p className="title">Pop-up blocked</p>
          <p className="text" style={{ textAlign: "left" }}>
            Please allow pop-ups for this site to open the link in a new tab,
            clicking <span>Proceed</span> will open the link in a new tab.
          </p>
        </div>
      </div>
      {/* end of content */}
      {/* btn box start */}
      <div
        style={{ padding: "unset", marginTop: "3rem" }}
        className="btn-box btn-box-two"
      >
        <RavenButton
          onClick={() => {
            window.open(url, "_blank");
            setStep(1);
          }}
          color={`deep-green-dark`}
          label={`Proceed`}
        />
      </div>
      {/* btn box end */}
    </>
  );
};

export default AllowPopUpNotification;
