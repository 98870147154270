import { createContext, useState } from "react";
// import ngnIcon from "../assets/ngnIcon.svg";

const ActionContext = createContext({
  currencyObj: {},
  setCurrencyObj: (param) => {},
});

export function ActionContextProvider(props) {
  const [currencyObjVal, setCurrencyObjVal] = useState("");

  function setCurrencyObjValFunction(param) {
    setCurrencyObjVal(param);
  }

  const context = {
    currencyObj: currencyObjVal,
    setCurrencyObj: setCurrencyObjValFunction,
  };

  return <ActionContext.Provider value={context}>{props.children}</ActionContext.Provider>;
}

export default ActionContext;
